import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {clearLicense, getLicense} from "../redux/actions/licenseAction";
import {RootState} from "../redux/store";
import Loader from "../components/Loader/Loader";
import image from '../assets/background.webp'

const PageWrapper = styled('main')`
    position: relative;
    height: 100%;
    //background: ${({theme}) => theme.palette.primary.dark};
    background: url(${image}) center center no-repeat;
    background-size: cover;
    &::before {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: black;
        opacity: .7;
        z-index: 0;
    }
`

const StyledContainer = styled(Container)`
    height: 100%;
    position: relative;
    z-index: 10;
`

const StyledBox = styled(Box)`
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
`

const StyledPaper = styled(Paper)`
    padding: ${({theme}) => theme.spacing(2)};
`

const StyledForm = styled('form')``

interface IFrom {
    serial: string
}

const LicenseView = () => {

    const dispatch = useDispatch()
    const state = useSelector((state: RootState) => state.licenses.currentLicense)
    const {register, handleSubmit} = useForm<IFrom>()

    const handleGetKey: SubmitHandler<IFrom> = (data) => {
        dispatch(getLicense(data.serial))
    }

    const handleLicenseClear = () => {
        if (state.data) {
            dispatch(clearLicense(state.data.key))
        }
    }

    return (
        <PageWrapper>
            <StyledContainer maxWidth="xs">
                <StyledBox>
                    <Typography
                        fontFamily="Rebork"
                        mb={2} color="white" fontSize={42} component="h1" textAlign="center">ROOMS</Typography>
                    <StyledPaper elevation={5}>
                        {state.data && !state.isLoading &&
                            <TableContainer>
                                <Table sx={{
                                    marginBottom: "16px"
                                }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>KEY</TableCell>
                                            <TableCell>{state.data.key}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Machine ID</TableCell>
                                            <TableCell>{state.data.machine_id || 'Empty'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Machine OS</TableCell>
                                            <TableCell>{state.data.machine_os || 'Empty'}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Button variant="contained" fullWidth onClick={handleLicenseClear}>Remove current
                                    machine</Button>
                            </TableContainer>
                        }
                        {state.isLoading && <Loader/>}
                        {!state.isLoading && !state.data &&
                            <StyledForm onSubmit={handleSubmit(handleGetKey)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField label="Serial Key" placeholder="Serial key"
                                                   fullWidth {...register('serial', {required: true})}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" fullWidth>Check license</Button>
                                    </Grid>
                                    {state.error && (
                                        <Grid item xs={12}>
                                            <Alert severity="error" variant="filled">{state.error.message}</Alert>
                                        </Grid>
                                    )}
                                </Grid>
                            </StyledForm>
                        }
                    </StyledPaper>
                </StyledBox>
            </StyledContainer>
        </PageWrapper>
    )
}


export default LicenseView
